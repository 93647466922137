import React, { useState } from "react";
import hero from "../../image/first.png";
import { db } from "../../config/Firebase";
const Hero = ({main}) => {
  const [email, setEmail] = useState("");

  const addToUpdates = async () => {
    await db.collection("emails").doc(email).set({
      "email":email
    })
  };

  

  return (
    <>
      <div className="sm:flex sm:flex-row-reverse sm:items-center sm:justify-between md:pl-10 md:pr-10 lg:pl-14 lg:pr-12 xl:pl-44 xl:pr-44" ref={main}>
        <div className="">
          <img className="" src={hero} alt="" />
        </div>
        <div className="w-full md:w-3/5 md:text-left p-8 sm:p-0">
          <p className="pb-3 font-bold text-yellow-500"> * Coming this February!</p>
          <h1 className="text-3xl lg:text-5xl font-bold text-gray-900 w-3/4">
            We connect your friends and family on a
            <span className=" pl-2 pr-2 text-transparent bg-pricing-gradient bg-clip-text">
              Map
            </span>
            for a better Tailgating experience
          </h1>

          <div class="flex mt-10">
            <input
              class="rounded-l-lg p-3 border-t mr-0 border-b border-l text-gray-800 border-gray-200 bg-white"
              placeholder="Email Address"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <button
              class="px-2 md:px-4 rounded-r-lg bg-yellow-500  text-white font-bold p-3 uppercase border-yellow-500 border-t border-b border-r"
              onClick={(e) => {
                e.preventDefault()
                addToUpdates();
                alert("Email stored")
              }}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
