import {
  faCommentAlt,
  faUsers,
  faRoute,
  faLink,
} from "@fortawesome/free-solid-svg-icons";

export const featuresData = [
  {
    title: "Groups",
    icon: faUsers,
    description: "Make groups for your next tailgate with ease.",
  },
  {
    title: "Messaging",
    icon: faCommentAlt,
    description: "Message everyone in the group in the app itself.",
  },
  {
    title: "Navigation",
    icon: faRoute,
    description: "Fully automated navigation for everyone in the group.",
  },
  {
    title: "Connectivity",
    icon: faLink,
    description: "See everyone's ETA and location in the app.",
  },
];
