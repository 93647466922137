import React, { useRef } from "react";
import Future from "../../Component/Future/Future";
import Footer from "../../Component/Footer/Footer";
import Hero from "../../Component/Hero/Hero";
import Message from "../../Component/Message/Message";
import Navbar from "../../Component/Navbar/Navbar";
import ETA from "../../Component/ETA/ETA";


const Home = () => {
  const mainRef = useRef();
  const featuresRef = useRef();
  const futureRef = useRef();
  return (
    <>
      <Navbar main={mainRef} features={featuresRef} future={futureRef}/>
      <Hero main={mainRef}/>
      {/* <Partner /> */}
      {/* <Service /> */}
      <ETA features={featuresRef}/>
      <Message />
      {/* <Team /> */}
      {/* <Pricing /> */}
      <Future main={mainRef} future={futureRef}/>
      {/* <Testimonial /> */}
      <Footer />
    </>
  );
};

export default Home;
