import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FeaturesCard = ({ features }) => {
  return (
    <div className="transition duration-200 border border-gray-100 rounded-md shadow-sm bg-gray-50 hover:shadow-md hover:bg-white hover:border-transparent">
      <div className="max-w-md px-8 py-6">
        <div className="flex items-center justify-start w-16 h-16 mb-4">
          <FontAwesomeIcon
            className="text-red-600"
            icon={features.icon}
            size="2x"
          />
        </div>
        <div className="space-y-4">
          <h6 className="mb-2 text-xl font-semibold leading-5 text-gray-700 lg:text-2xl">
            {features.title}
          </h6>
          <p className="pb-4 text-gray-500">{features.description}</p>
        </div>
      </div>
    </div>
  );
};

export default FeaturesCard;
