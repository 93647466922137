import React, { useState } from "react";
import Column from "../Column/Column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

import { db } from "../../config/Firebase";

const footerColumnData = [
  {
    title: "SOLUTIONS",
    contentOne: "Marketing",
    contentTwo: "Analytics",
    contentThree: "Commerce",
    contentFour: "Insights",
  },
  {
    title: "SUPPORT",
    contentOne: "Pricing",
    contentTwo: "Documentation",
    contentThree: "Guides",
    contentFour: "Features",
  },
  {
    title: "COMPANY",
    contentOne: "About",
    contentTwo: "Blog",
    contentThree: "Jobs",
    contentFour: "Press",
    contentFive: "Partners",
  },
  {
    title: "LEGAL",
    contentOne: "Claim",
    contentTwo: "Privacy",
    contentThree: "Terms",
  },
];

const Footer = () => {
  const [email, setEmail] = useState("");

  const addToUpdates = async () => {
    await db.collection("emails").doc(email).set({
      email: email,
    });
  };

  return (
    <footer className="bg-gray-900 ">
      <div className="px-4 py-12 mx-auto lg:px-8 max-w-7xl">
        <div className="text-base text-left">
          <div className="divide-y divide-gray-700 divide-solid">
            {/* newsletter section start */}
            <div className="divide-y divide-gray-700 divide-solid">
              <div className="grid grid-cols-1 py-8 md:flex md:justify-between justify-items-center">
                <div className="text-center md:text-left">
                  <h6 className="pb-2 text-base font-semibold text-gray-300 text-md">
                    SUBSCRIBE TO GET THE LATEST UPDATES
                  </h6>
                  <p className="px-24 pt-2 text-sm text-gray-400 md:pt-0 md:text-base md:px-0">
                    The latest updates on new features, and releases when they
                    happen{" "}
                  </p>
                </div>
                <div className="pt-8 md:pt-0">
                  <form className=" md:mt-2" action="#" method="POST">
                    <div className="flex space-x-4 md:space-y-0 justify-items-evenly">
                      <div>
                        <label htmlFor="email-address" className="sr-only">
                          Email address
                        </label>
                        <input
                          id="email-address"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          className="relative block w-full px-3 py-3 text-gray-900 placeholder-gray-500 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                          placeholder="Email address"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>

                      <div>
                        <button
                          type="submit"
                          className="btn-tailgate"
                          onClick={(e) => {
                            e.preventDefault();
                            addToUpdates();
                            alert("Email stored");
                          }}
                        >
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              {/* newsletter section end */}

              {/* social section start */}
              <div className="pt-8 text-center md:flex md:justify-between">
                <div>
                  {/* <p className="text-gray-400">
                    {" "}
                    © {new Date().getFullYear()} Tailgate, All Rights Reserved
                  </p> */}
                </div>
                {/* icons pack */}
                <div className="flex items-center justify-center pt-6 md:pt-0">
                  <FontAwesomeIcon
                    className="ml-8 text-gray-400 cursor-pointer hover:text-white"
                    icon={faTwitter}
                    size="lg"
                    onClick={() =>
                      window.open("https://twitter.com/TailgateHelp")
                    }
                  />
                </div>
              </div>
              {/* social section end */}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
