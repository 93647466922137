import React from "react";
import third from "../../image/third.png";
const Message = () => {
  return (
    <>
      <div className="md:pl-10 md:pr-10 lg:pl-14 lg:pr-12 xl:pl-44 xl:pr-44">
        <div className="md:flex md:mt-0 items-center justify-between">
          <div className="p-8 sm:p-0">
            <h4 className="mb-3 font-bold text-yellow-500">Message</h4>
            <h5 className="mb-6 text-3xl font-bold text-gray-700 lg:text-5xl">
              All-in-one Tailgating{" "}
              <span className="block mt-3">for a better experience</span>
            </h5>
            <h4 className="my-6">
              {" "}
              In-built messaging for a hassle-free all in one experience
            </h4>
          </div>
          <div className="">
            <img className="w-full sm:w-auto" src={third} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Message;
