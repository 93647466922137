import React from "react";
import logo from "../../image/Logo.png"

const Navbar = ({main, features, future}) => {
  return (
    <div className="flex flex-row items-center justify-between w-full font-extrabold text-gray-800 bg-white md:pl-2 md:pr-2 lg:pl-4 lg:pr-4 xl:pl-36 xl:pr-36">
      <div className="px-8 py-8 text-2xl font-bold text-yellow-500 brand-logo">
        <img src={logo} width="75px" alt="" />
      </div>
      <ul className="hidden px-8 space-x-4 font-bold menu-list md:flex lg:flex-row text-md">
        <li
          className="px-3 py-3 menu-list-item cursor-pointer"
          onClick={() => {
            features.current.scrollIntoView({behavior: "smooth"});
          }}
        >
          <p>Features</p>
        </li>
        <li
          className="px-3 py-3 menu-list-item cursor-pointer"
          onClick={() => {
            future.current.scrollIntoView({ behavior: "smooth" });
          }}
        >
          <p>Future</p>
        </li>
        <button
          className="flex px-3 py-3 font-semibold text-white bg-yellow-500 borderBottom menu-list-item hover:bg-yellow-300 rounded-xl"
          type="" onClick={() => {main.current.scrollIntoView({ behavior: "smooth" });}}
        >
          Get Latest Updates
        </button>
      </ul>
    </div>
  );
};

export default Navbar;
