import React from "react";
import FeaturesCard from "./FeaturesCard";
import { featuresData } from "./FeaturesData";

const Future = ({main, future}) => {
  return (
    <section>
      <div className="px-4 pt-8 mx-auto pb-28 sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:pb-32 lg:pt-20" ref={future}>
        <div className="flex flex-col items-center lg:flex-row">
          <div className="max-w-xl pr-16 mx-auto mb-10">
            <h5 className="mb-6 text-3xl font-bold text-gray-700 lg:text-5xl">
              Future of Tailgating
              <span className="block">like you've never imagined</span>
            </h5>
            <p className="mb-6 text-gray-900">
              Do everything you could possible think of on your next tailgate WITH Tailgate.
            </p>
            <div className="flex items-center">
              <button
                type="submit"
                className="inline-flex items-center justify-center h-12 mr-6 font-medium btn-tailgate" onClick={() => {main.current.scrollIntoView({ behavior: "smooth" });}}
              >
                Get started
              </button>
            </div>
          </div>
          <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
            {featuresData.map((features, idx) => (
              <FeaturesCard features={features} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Future;
