import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from "./page/Home/Home";


function App() {
    return (
      <Router>
        <Switch>
           <Route exact path="/">
             <Home />
           </Route>
           <Route path="/">
      
           </Route>
           <Route path="/">
        
           </Route>
        </Switch>
     </Router>
    );
}

export default App;
