import firebase from "firebase/compat";
import "firebase/compat/firestore";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBY5ZaKAL13fdvcyDeckSEBtNlOmudG5nw",
  authDomain: "tailgate-website.firebaseapp.com",
  projectId: "tailgate-website",
  storageBucket: "tailgate-website.appspot.com",
  messagingSenderId: "319299954958",
  appId: "1:319299954958:web:4e95930d19cdac91fb4f5f",
  measurementId: "G-1B47KZKYHS",
});

const db = firebase.firestore();

db.settings({
  timestampsInSnapshots: true,
  merge: true,
});

const auth = firebaseApp.auth();
const storage = firebaseApp.storage();

export { db, auth, storage };
